<template>
  <div id="app" class="app-body">
    <div class="spacer-small"></div>
    <DomainNameQuestionnaire
      v-if="this.$route.query.serviceId === '629670307b1d31777e30fd06'"
    />
    <BrandNameQuestionnaire
      v-else-if="this.$route.query.serviceId === '62966f5e7b1d31777e30fd04'"
    />
    <BrandPromiseQuestionnaire
      v-else-if="this.$route.query.serviceId === '629670817b1d31777e30fd08'"
    />
    <BrandLogoQuestionnaire
      v-else-if="this.$route.query.serviceId === '629670cb7b1d31777e30fd0a'"
    />
    <BrandGuideQuestionnaire
      v-else-if="this.$route.query.serviceId === '629670ee7b1d31777e30fd0c'"
    />
    <CompanyIncorporationQuestionnaire
      v-else-if="this.$route.query.serviceId === '629671107b1d31777e30fd0e'"
    />
    <TrademarkQuestionnaire
      v-else-if="this.$route.query.serviceId === '6296712f7b1d31777e30fd10'"
    />

    <b-modal
      v-model="showProjectSelectionModal"
      has-modal-card
      :can-cancel="false"
    >
      <div class="modal-card">
        <section class="modal-card-body has-text-centered">
          <div v-if="projectLoading">
            <Loading />
          </div>
          <div v-if="!projectLoading">
            <div class="columns">
              <div class="column"></div>
              <div class="column has-text-left is-narrow">
                <p class="is-bold mt-2 mb-5 py-5">Give your project a name</p>
                <div v-if="projects && projects.length">
                  <p
                    v-for="(project, index) in projects"
                    :key="index"
                    class="mt-2"
                  >
                    <b-radio
                      v-model="selectedProject"
                      :native-value="project._id"
                    >
                      {{ project.name }}
                    </b-radio>
                  </p>
                  <b-button
                    class="mt-5"
                    @click="selectProject"
                    type="is-info"
                    expanded
                  >
                    Submit
                  </b-button>
                </div>
                <p v-if="projects && !projects.length">
                  You do not have any projects yet, create one using the option
                  below
                </p>
              </div>
              <div class="column"></div>
            </div>

            <hr />

            <p class="mt-2">Create a new Project:</p>
            <b-field class="mt-2 mb-5" position="is-centered">
              <b-input
                placeholder="Enter project name"
                icon="plus"
                v-model="newProjectName"
                required
              ></b-input>
              <p class="control">
                <b-button @click="createProject" type="is-info">
                  Create Project
                </b-button>
              </p>
            </b-field>
          </div>
        </section>
      </div>
    </b-modal>
  </div>
</template>

<script>
import DomainNameQuestionnaire from "../../components/questionnaires/DomainName.vue";
import BrandNameQuestionnaire from "../../components/questionnaires/BrandName.vue";
import BrandPromiseQuestionnaire from "../../components/questionnaires/BrandPromise.vue";
import BrandLogoQuestionnaire from "../../components/questionnaires/BrandLogo.vue";
import BrandGuideQuestionnaire from "../../components/questionnaires/BrandGuide.vue";
import CompanyIncorporationQuestionnaire from "../../components/questionnaires/CompanyIncorporation.vue";
import TrademarkQuestionnaire from "../../components/questionnaires/Trademark.vue";
import Loading from "../../components/Loading.vue";

export default {
  name: "OrderQuestionnaire",
  components: {
    BrandNameQuestionnaire,
    DomainNameQuestionnaire,
    BrandPromiseQuestionnaire,
    BrandLogoQuestionnaire,
    BrandGuideQuestionnaire,
    CompanyIncorporationQuestionnaire,
    TrademarkQuestionnaire,
    Loading,
  },
  data() {
    return {
      showProjectSelectionModal: true,
      projectLoading: true,
      newProjectName: "",
      selectedProject: undefined,
    };
  },
  computed: {
    projects() {
      return this.$store.state.projects;
    },
  },
  async created() {
    this.projectLoading = true;
    await this.$store.dispatch("getProjects");
    if (this.projects && this.projects.length) {
      this.selectedProject = this.projects[0]._id;
    }
    this.projectLoading = false;
  },
  methods: {
    async createProject() {
      this.projectLoading = true;
      const randomColor =
        "#" + Math.floor(Math.random() * 16777215).toString(16);
      await this.$store.dispatch("createProject", {
        name: this.newProjectName,
        hexColor: randomColor,
      });
      await this.$store.dispatch("getProjects");
      this.newProjectName = "";
      this.projectLoading = false;
    },
    selectProject() {
      const query = this.$route.query;
      this.$router.replace({ query: { ...query, p: this.selectedProject } });
      this.showProjectSelectionModal = false;
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/main.scss";
</style>
