<template>
  <div class="columns">
    <div class="column is-6">
      <p class="is-subtitle is-secondary is-bold">Brand Promise</p>

      <p class="py-5">
        Some say slogan, others say tagline. We call it a brand promise.
      </p>
      <p class="pb-5">
        A brand promise is a further way to convey what is distinct about your
        company, or your offering: a few, well-chosen words of associate,
        positive meaning for your target market.
      </p>
      <p class="pb-5">
        This is the right point in your early brand-building process to develop
        a winning Brand Promise. Answer these few questions and your Brandeeq
        expert will get to work.
      </p>
      <form @submit.prevent="submit" id="editorForm">
        <div class="mt-5">
          <p class="is-secondary is-semi-bold mt-4 pt-4">
            What is your brand name?
          </p>
          <div class="my-5">
            <b-input
              v-model="responses.q1"
              :disabled="locked"
              required
            ></b-input>
          </div>

          <p class="is-secondary is-semi-bold mt-4 pt-4">
            Was this name created by Brandeeq?
          </p>
          <div class="my-5">
            <div class="columns is-mobile">
              <div class="column is-narrow">
                <b-radio v-model="responses.q5" native-value="Yes" required>
                  Yes
                </b-radio>
              </div>
              <div class="column">
                <b-radio v-model="responses.q5" native-value="No" required>
                  No
                </b-radio>
              </div>
            </div>
          </div>

          <p class="is-secondary is-semi-bold mt-4 pt-4">
            Please describe your business or business idea. What is unique or
            new about what you are offering?
          </p>
          <div class="my-5">
            <b-input
              v-model="responses.q6"
              :disabled="locked"
              type="textarea"
              required
            ></b-input>
          </div>

          <p class="is-secondary is-semi-bold mt-4 pt-4">
            Are you selling a: product, service, technology, consultancy?
          </p>
          <div class="my-5">
            <b-select
              v-model="responses.q3"
              placeholder="Select an option"
              expanded
              required
            >
              <option default value="Product">Product</option>
              <option value="Service">Service</option>
              <option value="Technology">Technology</option>
              <option value="Consultancy">Consultancy</option>
            </b-select>
          </div>

          <p class="is-secondary is-semi-bold mt-4 pt-4">
            Can you describe the customers you want to target? For example, in
            which sector/s do they operate and what is the nature of their
            business?
          </p>
          <div class="my-5">
            <b-input
              v-model="responses.q2"
              :disabled="locked"
              type="textarea"
              required
            ></b-input>
          </div>

          <p class="is-secondary is-semi-bold mt-4 pt-4">
            Have you conducted any research into your target market? If yes, can
            you upload a summary of the results?
          </p>
          <div class="my-5 pb-5">
            <section>
              <b-field>
                <b-upload v-model="dropFiles" multiple drag-drop>
                  <section class="section">
                    <div class="content has-text-centered">
                      <p>
                        <b-icon icon="upload" size="is-large"> </b-icon>
                      </p>
                      <p>Drop your files here or click to upload</p>
                    </div>
                  </section>
                </b-upload>
              </b-field>

              <div class="tags">
                <span
                  v-for="(file, index) in dropFiles"
                  :key="index"
                  class="tag is-primary"
                >
                  {{ file.name }}
                  <button
                    class="delete is-small"
                    type="button"
                    @click="deleteDropFile(index)"
                  ></button>
                </span>
              </div>
            </section>
          </div>

          <p class="is-secondary is-semi-bold mt-4 pt-4">
            Which brand/s do you consider as your competition?
          </p>
          <div class="my-5">
            <b-input v-model="responses.q7" expanded required> </b-input>
          </div>

          <p class="is-secondary is-semi-bold mt-4 pt-4">
            Which attribute do you most want a Brand Promise to convey about
            your business?
          </p>
          <div class="my-5">
            <b-input v-model="responses.q4" expanded required> </b-input>
          </div>

          <p class="is-secondary is-semi-bold mt-4 pt-4">
            Do you prefer a brand promise in English, Urdu, or no preference?
          </p>
          <div class="my-5">
            <b-input v-model="responses.q9" expanded required> </b-input>
          </div>

          <div class="my-5" style="margin-top: 44px !important">
            <b-message type="is-warning">
              <i class="mdi mdi-information-outline mr-1"></i>
              We ask several questions a second time, as your strategy may have
              changed since we last provided a creative solution to you.
            </b-message>
          </div>

          <div class="my-5 pt-5">
            <button
              form="editorForm"
              class="button is-info"
              type="submit"
              :disabled="isSubmitting"
            >
              <i class="mdi mdi-check mr-1"></i>
              {{ isSubmitting ? "Submitting Response" : "Submit Response" }}
            </button>
          </div>
        </div>
      </form>
    </div>
    <div class="column has-text-centered">
      <img
        src="@/assets/img/service_illustrations/brand_promise_illustration.svg"
        width="90%"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "BrandPromise",
  props: {
    locked: Boolean,
  },
  computed: {
    orderId() {
      return this.$route.query.orderId;
    },
    serviceId() {
      return this.$route.query.id;
    },
  },
  data() {
    return {
      isSubmitting: false,
      order: undefined,
      dropFiles: [],
      responses: {
        q1: undefined,
        q2: undefined,
        q3: undefined,
        q4: undefined,
        q5: undefined,
        q6: undefined,
        q7: undefined,
        q8: undefined,
        q9: undefined,
      },
      questions: {
        q1: "What is your brand name?",
        q2: "Can you describe the customers you want to target? For example, in which sector/s do they operate and what is the nature of their business?",
        q3: "Are you selling a: product, service, technology, consultancy?",
        q4: "Which attribute do you most want a Brand Promise to convey about your business?",
        q5: "Was this name created by Brandeeq?",
        q6: "Please describe your business or business idea. What is unique or new about what you are offering?",
        q7: "Which brand/s do you consider as your competition?",
        q8: "Have you conducted any research into your target market? If yes, can you upload a summary of the results?",
        q9: "Do you prefer a brand promise in English, Urdu, or no preference?",
      },
    };
  },
  methods: {
    deleteDropFile(index) {
      this.dropFiles.splice(index, 1);
    },
    async createOrder() {
      this.order = await this.$store.dispatch("createOrder", this.serviceId);
    },
    async submitQuestions() {
      await this.$store.dispatch("submitQuestions", {
        id: this.orderId,
        request: { questions: this.questions },
      });
    },
    async submitResponses() {
      const request = new FormData();
      request.append("file", this.dropFiles[0]);
      const uploadedFileLink = await this.$store.dispatch(
        "uploadResponse",
        request
      );
      this.responses.q8 = "FILE#" + uploadedFileLink;
      await this.$store.dispatch("submitResponses", {
        id: this.orderId,
        request: { responses: this.responses },
      });
    },
    async submit() {
      this.isSubmitting = true;
      await this.submitQuestions();
      await this.submitResponses();
      this.$router.push({ name: "Dashboard" });
      this.isSubmitting = false;
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/main.scss";
</style>
