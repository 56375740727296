<template>
  <div class="columns">
    <div class="column is-6">
      <p class="is-subtitle is-secondary is-bold">Brand Name</p>
      <p class="py-5">
        A distinct brand name is the foundation asset for a new venture,
        product, service, or technology.
      </p>
      <p class="pb-5">
        Answer these few questions to create an effective brief for your naming
        expert. Your Brandeeq expert will then get to work, creating two,
        original brand name options, along with matching domain name
        availability.
      </p>
      <form @submit.prevent="submit" id="editorForm">
        <div class="mt-5">
          <p class="is-secondary is-semi-bold mt-4 pt-4">
            Please describe your business or business idea. Is there something
            unique about your offering?
          </p>
          <div class="my-5">
            <b-input
              v-model="responses.q1"
              :disabled="locked"
              required
            ></b-input>
          </div>

          <p class="is-secondary is-semi-bold mt-4 pt-4">
            Are you selling a: product, service, technology, consultancy?
          </p>
          <div class="my-5">
            <b-input
              v-model="responses.q2"
              :disabled="locked"
              required
            ></b-input>
          </div>

          <p class="is-secondary is-semi-bold mt-4 pt-4">
            Can you describe the customers that you want to target? For example,
            what do they do; in which sector/s do they operate?
          </p>
          <div class="my-5">
            <b-input
              v-model="responses.q5"
              :disabled="locked"
              required
            ></b-input>
          </div>

          <p class="is-secondary is-semi-bold mt-4 pt-4">
            Have you conducted any research into your target market? If yes,
            please upload a summary of the results.
          </p>
          <div class="my-5 pb-5">
            <section>
              <b-field>
                <b-upload v-model="dropFiles" multiple drag-drop>
                  <section class="section">
                    <div class="content has-text-centered">
                      <p>
                        <b-icon icon="upload" size="is-large"> </b-icon>
                      </p>
                      <p>Drop your files here or click to upload</p>
                    </div>
                  </section>
                </b-upload>
              </b-field>

              <div class="tags">
                <span
                  v-for="(file, index) in dropFiles"
                  :key="index"
                  class="tag is-primary"
                >
                  {{ file.name }}
                  <button
                    class="delete is-small"
                    type="button"
                    @click="deleteDropFile(index)"
                  ></button>
                </span>
              </div>
            </section>
          </div>

          <p class="is-secondary is-semi-bold mt-4 pt-4">
            Which brand/s do you consider as your competition?
          </p>
          <div class="my-5">
            <b-input
              v-model="responses.q7"
              :disabled="locked"
              required
            ></b-input>
          </div>

          <p class="is-secondary is-semi-bold mt-4 pt-4">
            Choose one attribute that you want your brand to be known for (e.g.,
            price, speed, trust)
          </p>
          <div class="my-5">
            <b-input
              v-model="responses.q3"
              :disabled="locked"
              required
            ></b-input>
          </div>

          <p class="is-secondary is-semi-bold mt-4 pt-4">
            These are several types of brand names, including:
          </p>
          <b-message v-if="errorQ4" type="is-info" class="mt-4">
            <i class="mdi mdi-information-outline mr-1"></i>
            Choose the two category types that appeal most.
          </b-message>
          <div class="my-5">
            <b-checkbox
              v-model="responses.q4"
              class="mb-4"
              native-value="Plain-descriptive* (e.g. Junaid Jamshed, Mitchell’s)"
            >
              Plain-descriptive* (e.g. Junaid Jamshed, Mitchell’s)
            </b-checkbox>
            <br />
            <b-checkbox
              v-model="responses.q4"
              class="mb-4"
              native-value="Word-combo (e.g. AirBlue, Pakola, 1-Link)"
            >
              Word-combo (e.g. AirBlue, Pakola, 1-Link)
            </b-checkbox>
            <br />
            <b-checkbox
              v-model="responses.q4"
              class="mb-4"
              native-value="Associative (e.g. Brighto Paints, Khaadi, Rooh Afza,) "
            >
              Associative (e.g. Brighto Paints, Khaadi, Rooh Afza,)
            </b-checkbox>
            <br />
            <b-checkbox
              v-model="responses.q4"
              class="mb-4"
              native-value="Invented (e.g. Google, Omore, Xerox) "
            >
              Invented (e.g. Google, Omore, Xerox)
            </b-checkbox>
          </div>

          <p class="is-secondary is-semi-bold mt-4 pt-4">
            Do you prefer a name in English, Urdu, or no preference?
          </p>
          <div class="my-5">
            <b-input
              v-model="responses.q8"
              :disabled="locked"
              required
            ></b-input>
          </div>

          <div class="my-5" style="margin-top: 44px !important">
            <b-message type="is-warning">
              <i class="mdi mdi-information-outline mr-1"></i>
              Building a distinct, protectable brand from a plain-descriptive
              name type is usually the most difficult. For this reason, Brandeeq
              does not recommend plain-descriptive names.
            </b-message>
          </div>

          <div class="my-5 pt-5">
            <button
              form="editorForm"
              class="button is-info"
              type="submit"
              :disabled="isSubmitting || errorQ4"
            >
              <i class="mdi mdi-check mr-1"></i>
              {{ isSubmitting ? "Submitting Response" : "Submit Response" }}
            </button>
          </div>
        </div>
      </form>
    </div>
    <div class="column has-text-centered">
      <img
        src="@/assets/img/service_illustrations/brand_name_illustration.svg"
        width="90%"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "BrandName",
  props: {
    locked: Boolean,
  },
  computed: {
    orderId() {
      return this.$route.query.orderId;
    },
    serviceId() {
      return this.$route.query.id;
    },
    errorQ4() {
      return this.responses.q4.length != 2;
    },
  },
  data() {
    return {
      isSubmitting: false,
      order: undefined,
      dropFiles: [],
      responses: {
        q1: undefined,
        q2: undefined,
        q3: undefined,
        q4: [],
        q5: undefined,
        q6: undefined,
        q7: undefined,
        q8: undefined,
      },
      questions: {
        q1: "Please describe your business or business idea. Is there something unique about your offering?",
        q2: "Are you selling a: product, service, technology, consultancy?",
        q3: "Choose one attribute that you want your brand to be known for (e.g., price, speed, trust)",
        q4: "These are the main types of brand names. Choose two that appeal most:",
        q5: "Can you describe the customers that you want to target? For example, what do they do; in which sector/s do they operate?",
        q6: "Have you conducted any research into your target market? If yes, please upload a summary of the results.",
        q7: "Which brand/s do you consider as your competition?",
        q8: "Do you prefer a name in English, Urdu, or no preference?",
      },
    };
  },
  methods: {
    deleteDropFile(index) {
      this.dropFiles.splice(index, 1);
    },
    async createOrder() {
      this.order = await this.$store.dispatch("createOrder", this.serviceId);
    },
    async submitQuestions() {
      await this.$store.dispatch("submitQuestions", {
        id: this.orderId,
        request: { questions: this.questions },
      });
    },
    async submitResponses() {
      const request = new FormData();
      request.append("file", this.dropFiles[0]);
      const uploadedFileLink = await this.$store.dispatch(
        "uploadResponse",
        request
      );
      this.responses.q6 = "FILE#" + uploadedFileLink;
      await this.$store.dispatch("submitResponses", {
        id: this.orderId,
        request: { responses: this.responses },
      });
    },
    async submit() {
      if (!this.errorQ4) {
        this.isSubmitting = true;
        await this.submitQuestions();
        await this.submitResponses();
        this.$router.push({ name: "Dashboard" });
        this.isSubmitting = false;
      }
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/main.scss";
</style>
