<template>
  <div class="columns">
    <div class="column is-6">
      <p class="is-subtitle is-secondary is-bold">Company Incorporation</p>
      <p class="py-5">
        Why this service? First, by our analysis, this is the prime point in a
        new brand-building process to form a legal entity.
      </p>
      <p class="pb-5">
        Ready to form your company? Would you like an expert to manage the
        process? Or, do you need advice to first understand the differences
        between company types. Brandeeq can assist:
      </p>
      <form @submit.prevent="submit" id="editorForm">
        <div class="mt-5">
          <!-- <p class="is-secondary is-semi-bold mt-4 pt-4">
            What should be included in your brand guide:
          </p> -->
          <div class="mb-5">
            <b-radio
              class="mt-5"
              name="name"
              v-model="responseType"
              native-value="expertToWork"
              required
            >
              I want an expert to help me to form a:
            </b-radio>
            <div v-if="responseType == 'expertToWork'" class="ml-5 mt-2 pl-2">
              <div class="my-5">
                <b-select v-model="responses.q1" expanded required>
                  <option default value="Sole proprietorship">
                    Sole proprietorship
                  </option>
                  <option value="Partnership/limited liability partnership">
                    Partnership/limited liability partnership
                  </option>
                  <option value="Not for profit (NPO)">
                    Not for profit (NPO)
                  </option>
                  <option value="Limited liability company (Private limited)">
                    Limited liability company (Private limited)
                  </option>
                  <option value="Limited liability company (Public limited)">
                    Limited liability company (Public limited)
                  </option>
                </b-select>
              </div>
              <p class="is-secondary is-semi-bold mt-4 pt-4">
                Please provide the following information. This will be required
                for the formation of any company type. Your expert will be in
                contact to gather any further info required.
              </p>
              <p class="is-secondary is-semi-bold mt-4 pt-4">
                CNIC/NICOP/Passport number
              </p>
              <div class="my-5">
                <b-input
                  v-model="responses.q2"
                  :disabled="locked"
                  required
                ></b-input>
              </div>
              <p class="is-secondary is-semi-bold mt-4 pt-4">
                Mobile/cell phone number
              </p>
              <div class="my-5">
                <b-input
                  v-model="responses.q3"
                  :disabled="locked"
                  required
                ></b-input>
              </div>
              <p class="is-secondary is-semi-bold mt-4 pt-4">
                Active email address
              </p>
              <div class="my-5">
                <b-input
                  v-model="responses.q4"
                  :disabled="locked"
                  required
                ></b-input>
              </div>
              <p class="is-secondary is-semi-bold mt-4 pt-4">Nationality</p>
              <div class="my-5">
                <b-input
                  v-model="responses.q5"
                  :disabled="locked"
                  required
                ></b-input>
              </div>
              <p class="is-secondary is-semi-bold mt-4 pt-4">
                Residential address
              </p>
              <div class="my-5">
                <b-input
                  v-model="responses.q6"
                  :disabled="locked"
                  required
                ></b-input>
              </div>
              <p class="is-secondary is-semi-bold mt-4 pt-4">
                Accounting period
              </p>
              <div class="my-5">
                <b-input
                  v-model="responses.q7"
                  :disabled="locked"
                  required
                ></b-input>
              </div>
              <p class="is-secondary is-semi-bold mt-4 pt-4">Business name</p>
              <div class="my-5">
                <b-input
                  v-model="responses.q8"
                  :disabled="locked"
                  required
                ></b-input>
              </div>
              <p class="is-secondary is-semi-bold mt-4 pt-4">
                Business address
              </p>
              <div class="my-5">
                <b-input
                  v-model="responses.q9"
                  :disabled="locked"
                  required
                ></b-input>
              </div>
              <p class="is-secondary is-semi-bold mt-4 pt-4">
                Principal business activity
              </p>
              <div class="my-5">
                <b-input
                  v-model="responses.q10"
                  :disabled="locked"
                  required
                ></b-input>
              </div>
            </div>
            <br />
            <b-radio
              v-model="responseType"
              class="mt-5"
              name="name"
              native-value="expertToAdvice"
              required
            >
              I’d like to get expert advice before starting the process to form
              a company.
            </b-radio>
          </div>

          <div class="my-5 pt-5">
            <button
              form="editorForm"
              class="button is-info"
              type="submit"
              :disabled="isSubmitting"
            >
              <i class="mdi mdi-check mr-1"></i>
              {{ isSubmitting ? "Submitting Response" : "Submit Response" }}
            </button>
          </div>
        </div>
      </form>
    </div>
    <div class="column has-text-centered">
      <img
        src="@/assets/img/service_illustrations/company_formation_illustration.svg"
        width="90%"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "CompanyIncorporation",
  props: {
    locked: Boolean,
  },
  computed: {
    orderId() {
      return this.$route.query.orderId;
    },
    serviceId() {
      return this.$route.query.id;
    },
  },
  data() {
    return {
      isSubmitting: false,
      order: undefined,
      responseType: undefined,
      responses: {
        q1: undefined,
        q2: undefined,
        q3: undefined,
        q4: undefined,
        q5: undefined,
        q6: undefined,
        q7: undefined,
        q8: undefined,
        q9: undefined,
        q10: undefined,
      },
      questions: {
        q1: "I want an expert to help me to form a:",
        q2: "CNIC/NICOP/Passport number",
        q3: "Mobile/cell phone number",
        q4: "Active email address",
        q5: "Nationality",
        q6: "Residential address",
        q7: "Accounting period",
        q8: "Business name",
        q9: "Business address",
        q10: "Principal business activity",
      },
    };
  },
  methods: {
    async createOrder() {
      this.order = await this.$store.dispatch("createOrder", this.serviceId);
    },
    async submitQuestions() {
      await this.$store.dispatch("submitQuestions", {
        id: this.orderId,
        request: {
          questions:
            this.responseType == "expertToWork"
              ? this.questions
              : {
                  q1: "Would you like to get expert advice before starting the process to form a company.",
                },
        },
      });
    },
    async submitResponses() {
      await this.$store.dispatch("submitResponses", {
        id: this.orderId,
        request: {
          responses:
            this.responseType == "expertToWork"
              ? this.responses
              : {
                  q1: "Yes",
                },
        },
      });
    },
    async submit() {
      this.isSubmitting = true;
      await this.submitQuestions();
      await this.submitResponses();
      this.$router.push({ name: "Dashboard" });
      this.isSubmitting = false;
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/main.scss";
</style>
