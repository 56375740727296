<template>
  <div class="columns">
    <div class="column is-6">
      <p class="is-subtitle is-secondary is-bold">Domain Name Purchase</p>
      <p class="py-5">
        Use the information provided by your brand naming expert to purchase a
        web domain name that’s consistent with your new, distinct brand name.
      </p>
      <p>
        Connect directly to one of our affiliate domain registrar companies and
        secure the domain name which is right for your brand.
      </p>

      <div class="mt-5">
        <p class="is-secondary is-semi-bold mt-4 pt-4">GoDaddy</p>
        <div class="my-5">
          <a href="https://www.godaddy.com" target="_blank"
            >https://www.godaddy.com</a
          >
        </div>
        <p class="is-secondary is-semi-bold mt-4 pt-4">Namecheap</p>
        <div class="my-5">
          <a href="https://www.namecheap.com" target="_blank"
            >https://www.namecheap.com</a
          >
        </div>
        <p class="is-secondary is-semi-bold mt-4 pt-4">FastComet</p>
        <div class="my-5">
          <a href="https://www.fastcomet.com" target="_blank"
            >https://www.fastcomet.com</a
          >
        </div>
      </div>

      <div v-if="false" class="mt-5">
        <form @submit.prevent="submit" id="editorForm">
          <p class="is-secondary is-semi-bold mt-4 pt-4">
            Confirm your brand name?
          </p>
          <div class="my-5">
            <b-input
              v-model="responses.q1"
              placeholder="Enter your brand name"
              required
            ></b-input>
          </div>

          <p class="is-secondary is-semi-bold mt-4 pt-4">Organization type?</p>
          <div class="my-5">
            <b-select
              v-model="responses.q2"
              placeholder="Select a type"
              required
              expanded
            >
              <option value="Business">Business</option>
              <option value="Education">Education</option>
              <option value="Government">Government</option>
              <option value="Information Technology">
                Information Technology
              </option>
            </b-select>
          </div>

          <p class="is-secondary is-semi-bold mt-4 pt-4">
            Do you have serious plans to expand beyond Pakistan?
          </p>
          <div class="my-5">
            <!-- <b-input v-model="responses.q3" :disabled="locked"></b-input> -->
            <b-radio
              v-model="responses.q3"
              name="name"
              native-value="Yes"
              required
            >
              Yes
            </b-radio>
            <b-radio
              v-model="responses.q3"
              name="name"
              native-value="No"
              class="ml-5"
              required
            >
              No
            </b-radio>
          </div>

          <div class="my-5 pt-5">
            <button
              form="editorForm"
              class="button is-info"
              type="submit"
              :disabled="isSubmitting"
            >
              <i class="mdi mdi-check mr-1"></i>
              {{ isSubmitting ? "Submitting Response" : "Submit Response" }}
            </button>
          </div>
        </form>
      </div>
    </div>
    <div class="column has-text-centered">
      <img
        src="@/assets/img/service_illustrations/brand_domain_illustration.svg"
        width="90%"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "DomainNameQuestionnaire",
  props: {
    locked: Boolean,
  },
  computed: {
    orderId() {
      return this.$route.query.orderId;
    },
    serviceId() {
      return this.$route.query.id;
    },
  },
  data() {
    return {
      isSubmitting: false,
      order: undefined,
      responses: {
        q1: undefined,
        q2: "Business",
        q3: undefined,
      },
      questions: {
        q1: "Confirm your brand name?",
        q2: "Organization type?",
        q3: "Do you have serious plans to expand beyond Pakistan?",
      },
    };
  },
  methods: {
    async createOrder() {
      this.order = await this.$store.dispatch("createOrder", this.serviceId);
    },
    async submitQuestions() {
      await this.$store.dispatch("submitQuestions", {
        id: this.order._id,
        request: { questions: this.questions },
      });
    },
    async submitResponses() {
      await this.$store.dispatch("submitResponses", {
        id: this.order._id,
        request: { responses: this.responses },
      });
    },
    async submit() {
      this.isSubmitting = true;
      await this.submitQuestions();
      await this.submitResponses();
      this.$router.push({ name: "Dashboard" });
      this.isSubmitting = false;
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/main.scss";
</style>
