<template>
  <div class="columns">
    <div class="column is-6">
      <p class="is-subtitle is-secondary is-bold">Brand Templates</p>
      <p class="py-5">
        Take the next step to ensure the consistent application of visual
        identity, every time your brand appears, by extending your brand to
        several foundation templates which are a must for all businesses.
      </p>
      <p class="pb-5">
        We will create 1x branded letterhead template, 1x branded internal
        template and 1x business card template. These will include your current
        logo & will introduce some basic visual identity elements.
      </p>
      <form @submit.prevent="submit" id="editorForm">
        <div class="mt-5">
          <p class="is-secondary is-semi-bold mt-4 pt-4">
            Upload a current logo & any identity guides associated with your
            brand in a zipped file.
          </p>

          <div class="my-5 pb-5">
            <section>
              <b-field>
                <b-upload v-model="dropFiles" multiple drag-drop required>
                  <section class="section">
                    <div class="content has-text-centered">
                      <p>
                        <b-icon icon="upload" size="is-large"> </b-icon>
                      </p>
                      <p>Drop your files here or click to upload</p>
                    </div>
                  </section>
                </b-upload>
              </b-field>

              <div class="tags">
                <span
                  v-for="(file, index) in dropFiles"
                  :key="index"
                  class="tag is-primary"
                >
                  {{ file.name }}
                  <button
                    class="delete is-small"
                    type="button"
                    @click="deleteDropFile(index)"
                  ></button>
                </span>
              </div>
            </section>
          </div>

          <p class="is-secondary is-semi-bold mt-4 pt-4">
            What format do you prefer for your brand template?
          </p>
          <div class="mb-5">
            <b-radio
              v-model="responses.q6"
              class="mt-5"
              name="name"
              native-value="Google Docs"
              required
            >
              Google Docs
            </b-radio>
            <br />
            <b-radio
              v-model="responses.q6"
              class="mt-5"
              name="name"
              native-value="Microsoft Word"
              required
            >
              Microsoft Word
            </b-radio>
          </div>

          <p class="is-secondary is-semi-bold mt-4 pt-4">
            Confirm the details to include in a letterhead & business card:
          </p>
          <div class="mb-5">
            <!-- <b-radio
              v-model="responseType"
              class="mt-5"
              name="name"
              native-value="optionA"
              required
            >
              Logo guidelines. This tells people what they can and cannot do
              with the logo.
            </b-radio>
            <br />
            <b-radio
              v-model="responseType"
              class="mt-5"
              name="name"
              native-value="optionB"
              required
            >
              Master letterhead template & a template for sharing internal
              information.
            </b-radio>
            <br />
            <b-radio
              v-model="responseType"
              class="mt-5"
              name="name"
              native-value="optionC"
              required
            >
              A business card template. For sample card, provide:
            </b-radio> -->
            <div class="mt-2">
              <p class="is-secondary is-semi-bold mt-4 pt-4">Name</p>
              <div class="my-5">
                <b-input
                  v-model="responses.q1"
                  :disabled="locked"
                  required
                ></b-input>
              </div>
              <p class="is-secondary is-semi-bold mt-4 pt-4">
                Telephone Number
              </p>
              <div class="my-5">
                <b-input
                  v-model="responses.q2"
                  :disabled="locked"
                  required
                ></b-input>
              </div>
              <p class="is-secondary is-semi-bold mt-4 pt-4">Email</p>
              <div class="my-5">
                <b-input
                  v-model="responses.q3"
                  :disabled="locked"
                  required
                ></b-input>
              </div>
              <p class="is-secondary is-semi-bold mt-4 pt-4">Address</p>
              <div class="my-5">
                <b-input
                  v-model="responses.q4"
                  :disabled="locked"
                  required
                ></b-input>
              </div>
              <p class="is-secondary is-semi-bold mt-4 pt-4">
                Any other information
              </p>
              <div class="my-5">
                <b-input v-model="responses.q5" :disabled="locked"></b-input>
              </div>
            </div>
          </div>

          <div class="my-5" style="margin-top: 44px !important">
            <b-message type="is-warning">
              <i class="mdi mdi-information-outline mr-1"></i>
              Brandeeq recommends that minimal information is provided.
            </b-message>
          </div>

          <div class="my-5 pt-5">
            <button
              form="editorForm"
              class="button is-info"
              type="submit"
              :disabled="isSubmitting"
            >
              <i class="mdi mdi-check mr-1"></i>
              {{ isSubmitting ? "Submitting Response" : "Submit Response" }}
            </button>
          </div>
        </div>
      </form>
    </div>
    <div class="column has-text-centered">
      <img
        src="@/assets/img/service_illustrations/brand_templates_illustration.svg"
        width="90%"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "BrandGuide",
  props: {
    locked: Boolean,
  },
  computed: {
    orderId() {
      return this.$route.query.orderId;
    },
    serviceId() {
      return this.$route.query.id;
    },
  },
  data() {
    return {
      isSubmitting: false,
      order: undefined,
      dropFiles: [],
      responseType: "optionC",
      responses: {
        q1: undefined,
        q2: undefined,
        q3: undefined,
        q4: undefined,
        q5: undefined,
        q6: undefined,
      },
      questions: {},
    };
  },
  methods: {
    deleteDropFile(index) {
      this.dropFiles.splice(index, 1);
    },
    async createOrder() {
      this.order = await this.$store.dispatch("createOrder", this.serviceId);
    },
    async submitQuestions() {
      let questions = {
        q1: "There seem's to be an error, contact customer for brief",
      };
      if (this.responseType == "optionA") {
        questions = {
          q1: "The current brand logo for your organization:",
          q2: "What should be included in your brand guide:",
        };
      } else if (this.responseType == "optionB") {
        questions = {
          q1: "The current brand logo for your organization:",
          q2: "What should be included in your brand guide:",
        };
      } else if (this.responseType == "optionC") {
        questions = {
          q1: "The current brand logo for your organization:",
          q2: "Confirm the details to include in a letterhead & business card:",
          q3: "Name",
          q4: "Telephone Number",
          q5: "Email",
          q6: "Address",
          q7: "Any other information",
          q8: "What format do you prefer for your brand template?",
        };
      }
      await this.$store.dispatch("submitQuestions", {
        id: this.orderId,
        request: { questions: questions },
      });
    },
    async submitResponses() {
      const request = new FormData();
      request.append("file", this.dropFiles[0]);
      const uploadedFileLink = await this.$store.dispatch(
        "uploadResponse",
        request
      );

      let responses = {
        q1: "",
      };
      if (this.responseType == "optionA") {
        responses = {
          q1: "FILE#" + uploadedFileLink,
          q2: "Logo guidelines. This tells people what they can and cannot do with the logo.",
        };
      } else if (this.responseType == "optionB") {
        responses = {
          q1: "FILE#" + uploadedFileLink,
          q2: "Master letterhead template & a template for sharing internal information",
        };
      } else if (this.responseType == "optionC") {
        responses = {
          q1: "FILE#" + uploadedFileLink,
          q2: "",
          q3: this.responses.q1,
          q4: this.responses.q2,
          q5: this.responses.q3,
          q6: this.responses.q4,
          q7: this.responses.q5,
          q8: this.responses.q6,
        };
      }
      await this.$store.dispatch("submitResponses", {
        id: this.orderId,
        request: { responses: responses },
      });
    },
    async submit() {
      this.isSubmitting = true;
      await this.submitQuestions();
      await this.submitResponses();
      this.$router.push({ name: "Dashboard" });
      this.isSubmitting = false;
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/main.scss";
</style>
