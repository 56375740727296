<template>
  <div class="columns">
    <div class="column is-6">
      <p class="is-subtitle is-secondary is-bold">Brand Logo + Guidelines</p>
      <p class="py-5">
        Do you know the logo landscape in Pakistan? The Brandeeq team does, as
        we researched it extensively. We’re confident we’ll give you the
        distinct and strong brand logo your business deserves.
      </p>
      <p class="pb-5">
        Answer these few questions to create a great brief for your brand logo
        designer:
      </p>
      <div class="mt-5">
        <form @submit.prevent="submit" id="editorForm">
          <p class="is-secondary is-semi-bold mt-4 pt-4">
            Confirm your brand name:
          </p>
          <div class="my-5">
            <b-input
              v-model="responses.q1"
              placeholder="Enter your brand name"
              required
            ></b-input>
          </div>

          <p class="is-secondary is-semi-bold mt-4 pt-4">
            Please write some description of the organization and it's target
            audience:
          </p>
          <div class="my-5">
            <b-input
              v-model="responses.q7"
              placeholder="Enter your brand name"
              type="textarea"
              required
            ></b-input>
          </div>

          <p class="is-secondary is-semi-bold mt-4 pt-4">
            Which typeface style looks or feels right for your brand?
          </p>
          <div class="my-5">
            <b-radio
              v-if="false"
              v-model="responses.q2"
              native-value="IMG#TypeFaces/1.svg"
            >
              <img src="@/assets/img/responses/TypeFaces/1.svg" />
            </b-radio>
            <b-radio v-model="responses.q2" native-value="IMG#TypeFaces/2.svg">
              <img src="@/assets/img/responses/TypeFaces/2.svg" />
            </b-radio>
            <br />
            <b-radio
              v-if="false"
              v-model="responses.q2"
              native-value="IMG#TypeFaces/3.svg"
            >
              <img src="@/assets/img/responses/TypeFaces/3.svg" />
            </b-radio>
            <b-radio v-model="responses.q2" native-value="IMG#TypeFaces/4.svg">
              <img src="@/assets/img/responses/TypeFaces/4.svg" />
            </b-radio>
            <br />
            <b-radio
              v-if="false"
              v-model="responses.q2"
              native-value="IMG#TypeFaces/5.svg"
            >
              <img src="@/assets/img/responses/TypeFaces/5.svg" />
            </b-radio>
            <b-radio
              v-if="false"
              v-model="responses.q2"
              native-value="IMG#TypeFaces/6.svg"
            >
              <img src="@/assets/img/responses/TypeFaces/6.svg" />
            </b-radio>
            <br />
            <b-radio
              v-if="false"
              v-model="responses.q2"
              native-value="IMG#TypeFaces/7.svg"
            >
              <img src="@/assets/img/responses/TypeFaces/7.svg" />
            </b-radio>
            <b-radio
              v-if="false"
              v-model="responses.q2"
              native-value="IMG#TypeFaces/8.svg"
            >
              <img src="@/assets/img/responses/TypeFaces/8.svg" />
            </b-radio>
          </div>

          <p class="is-secondary is-semi-bold mt-4 pt-4">
            Choose two color palettes that appeal the most:
          </p>
          <b-message v-if="errorQ3" type="is-danger" class="mt-4">
            <i class="mdi mdi-information-outline mr-1"></i>
            You cannot select more than two options
          </b-message>
          <div class="my-5">
            <div class="columns">
              <div class="column">
                <b-checkbox
                  v-model="responses.q3"
                  native-value="IMG#ColourPalettes/brandeeq_s_choice.svg"
                >
                  <img
                    src="@/assets/img/responses/ColourPalettes/brandeeq_s_choice.svg"
                  />
                </b-checkbox>
              </div>
              <div class="column">
                <b-checkbox
                  v-model="responses.q3"
                  native-value="IMG#ColourPalettes/light_neutrals.svg"
                >
                  <img
                    src="@/assets/img/responses/ColourPalettes/light_neutrals.svg"
                  />
                </b-checkbox>
              </div>
              <div class="column">
                <b-checkbox
                  v-model="responses.q3"
                  native-value="IMG#ColourPalettes/dark_neutrals.svg"
                >
                  <img
                    src="@/assets/img/responses/ColourPalettes/dark_neutrals.svg"
                  />
                </b-checkbox>
              </div>
            </div>

            <div class="columns">
              <div class="column">
                <b-checkbox
                  v-model="responses.q3"
                  native-value="IMG#ColourPalettes/pinks.svg"
                >
                  <img src="@/assets/img/responses/ColourPalettes/pinks.svg" />
                </b-checkbox>
              </div>
              <div class="column">
                <b-checkbox
                  v-model="responses.q3"
                  native-value="IMG#ColourPalettes/purples.svg"
                >
                  <img
                    src="@/assets/img/responses/ColourPalettes/purples.svg"
                  />
                </b-checkbox>
              </div>
              <div class="column">
                <b-checkbox
                  v-model="responses.q3"
                  native-value="IMG#ColourPalettes/blues.svg"
                >
                  <img src="@/assets/img/responses/ColourPalettes/blues.svg" />
                </b-checkbox>
              </div>
            </div>

            <div class="columns">
              <div class="column">
                <b-checkbox
                  v-model="responses.q3"
                  native-value="IMG#ColourPalettes/aquas.svg"
                >
                  <img src="@/assets/img/responses/ColourPalettes/aquas.svg" />
                </b-checkbox>
              </div>
              <div class="column">
                <b-checkbox
                  v-model="responses.q3"
                  native-value="IMG#ColourPalettes/greens.svg"
                >
                  <img src="@/assets/img/responses/ColourPalettes/greens.svg" />
                </b-checkbox>
              </div>
              <div class="column">
                <b-checkbox
                  v-model="responses.q3"
                  native-value="IMG#ColourPalettes/yellows.svg"
                >
                  <img
                    src="@/assets/img/responses/ColourPalettes/yellows.svg"
                  />
                </b-checkbox>
              </div>
            </div>

            <div class="columns">
              <div class="column">
                <b-checkbox
                  v-model="responses.q3"
                  native-value="IMG#ColourPalettes/oranges.svg"
                >
                  <img
                    src="@/assets/img/responses/ColourPalettes/oranges.svg"
                  />
                </b-checkbox>
              </div>
              <div class="column">
                <b-checkbox
                  v-model="responses.q3"
                  native-value="IMG#ColourPalettes/reds.svg"
                >
                  <img src="@/assets/img/responses/ColourPalettes/reds.svg" />
                </b-checkbox>
              </div>

              <div class="column"></div>
            </div>
          </div>

          <p class="is-secondary is-semi-bold mt-4 pt-4">
            Consider these logo types, Choose two options that appeal the most:
          </p>
          <b-message v-if="errorQ4" type="is-danger" class="mt-4">
            <i class="mdi mdi-information-outline mr-1"></i>
            You cannot select more than two options
          </b-message>
          <div class="my-5">
            <div class="columns">
              <div v-if="false" class="column is-narrow">
                <b-checkbox
                  v-model="responses.q4"
                  native-value="IMG#LogoTypes/abstract.svg"
                >
                  <img src="@/assets/img/responses/LogoTypes/abstract.svg" />
                </b-checkbox>
              </div>
              <div class="column is-narrow">
                <b-checkbox
                  v-model="responses.q4"
                  native-value="IMG#LogoTypes/combination.svg"
                >
                  <img src="@/assets/img/responses/LogoTypes/combination.svg" />
                </b-checkbox>
              </div>
              <div class="column is-narrow">
                <b-checkbox
                  v-model="responses.q4"
                  native-value="IMG#LogoTypes/emblem.svg"
                >
                  <img src="@/assets/img/responses/LogoTypes/emblem.svg" />
                </b-checkbox>
              </div>
              <div class="column is-narrow">
                <b-checkbox
                  v-model="responses.q4"
                  native-value="IMG#LogoTypes/wordmark.svg"
                >
                  <img src="@/assets/img/responses/LogoTypes/wordmark.svg" />
                </b-checkbox>
              </div>
            </div>

            <div class="columns">
              <div class="column is-narrow">
                <b-checkbox
                  v-model="responses.q4"
                  native-value="IMG#LogoTypes/lettermark.svg"
                >
                  <img src="@/assets/img/responses/LogoTypes/lettermark.svg" />
                </b-checkbox>
              </div>

              <div class="column is-narrow">
                <b-checkbox
                  v-model="responses.q4"
                  native-value="IMG#LogoTypes/mascot.svg"
                >
                  <img src="@/assets/img/responses/LogoTypes/mascot.svg" />
                </b-checkbox>
              </div>
              <div v-if="false" class="column is-narrow">
                <b-checkbox
                  v-model="responses.q4"
                  native-value="IMG#LogoTypes/pictorial.svg"
                >
                  <img src="@/assets/img/responses/LogoTypes/pictorial.svg" />
                </b-checkbox>
              </div>
            </div>
          </div>

          <p class="is-secondary is-semi-bold mt-4 pt-4">
            What is your vision for the future of the business?
          </p>
          <div class="my-5">
            <b-input v-model="responses.q8" required></b-input>
          </div>

          <p class="is-secondary is-semi-bold mt-4 pt-4">
            If there was a single idea or a feeling that can be distilled into
            your new logo, what would it be?
          </p>
          <div class="my-5">
            <b-input v-model="responses.q9"></b-input>
          </div>

          <p class="is-secondary is-semi-bold mt-4 pt-4">
            What are your brand pain points and do you have any unique selling
            points (USPs)?
          </p>
          <div class="my-5">
            <b-input v-model="responses.q10" required></b-input>
          </div>

          <p class="is-secondary is-semi-bold mt-4 pt-4">
            Choose one attribute that you want your brand to be known for (e.g.,
            price, speed, trust)
          </p>
          <div class="my-5">
            <b-input v-model="responses.q5" required></b-input>
          </div>

          <p class="is-secondary is-semi-bold mt-4 pt-4">
            Confirm your brand promise wording, if you want the designer to
            integrate a brand promise into your brand logo:
          </p>
          <div class="my-5">
            <b-input v-model="responses.q6"></b-input>
          </div>

          <div class="my-5 pt-5">
            <button
              form="editorForm"
              class="button is-info"
              type="submit"
              :disabled="isSubmitting || errorQ3 || errorQ4"
            >
              <i class="mdi mdi-check mr-1"></i>
              {{ isSubmitting ? "Submitting Response" : "Submit Response" }}
            </button>
          </div>
        </form>
      </div>
    </div>
    <div class="column has-text-centered">
      <img
        src="@/assets/img/service_illustrations/brand_logo_illustration.svg"
        width="90%"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "BrandLogo",
  props: {
    locked: Boolean,
  },
  computed: {
    orderId() {
      return this.$route.query.orderId;
    },
    serviceId() {
      return this.$route.query.id;
    },
    errorQ3() {
      return this.responses.q3.length > 2;
    },
    errorQ4() {
      return this.responses.q4.length > 2;
    },
  },
  data() {
    return {
      isSubmitting: false,
      order: undefined,
      responses: {
        q1: undefined,
        q2: undefined,
        q3: [],
        q4: [],
        q5: "",
        q6: "",
        q7: "",
        q8: "",
        q9: "",
        q10: "",
      },
      questions: {
        q1: "Confirm your brand name:",
        q2: "Which typeface style looks or feels right for your brand?",
        q3: "Choose two color palettes that appeal the most:",
        q4: "Consider these logo types, Choose two options that appeal the most:",
        q5: "Choose one attribute that you want your brand to be known for (e.g., price, speed, trust)",
        q6: "Confirm your brand promise wording, if you want the designer to integrate a brand promise into your brand logo:",
        q7: "Please write some description of the organization and it's target audience:",
        q8: "What is your vision for the future of the business?",
        q9: "If there was a single idea or a feeling that can be distilled into your new logo, what would it be?",
        q10: "What are your brand pain points and do you have any unique selling points (USPs)?",
      },
    };
  },
  methods: {
    async createOrder() {
      this.order = await this.$store.dispatch("createOrder", this.serviceId);
    },
    async submitQuestions() {
      await this.$store.dispatch("submitQuestions", {
        id: this.orderId,
        request: { questions: this.questions },
      });
    },
    async submitResponses() {
      await this.$store.dispatch("submitResponses", {
        id: this.orderId,
        request: { responses: this.responses },
      });
    },
    async submit() {
      this.isSubmitting = true;
      await this.submitQuestions();
      await this.submitResponses();
      this.$router.push({ name: "Dashboard" });
      this.isSubmitting = false;
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/main.scss";
</style>
